









































































import {
  SfHeading, SfButton, SfRadio, SfLoader,
} from '@storefront-ui/vue';

import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import useCart from '~/modules/checkout/composables/useCart';
import useShippingProvider from '~/modules/checkout/composables/useShippingProvider';
import getShippingMethodPrice from '~/helpers/checkout/getShippingMethodPrice';

import type { SelectedShippingMethod, AvailableShippingMethod } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'VsfShippingProvider',
  components: {
    SfHeading,
    SfButton,
    SfRadio,
    SfLoader,
  },
  props: {
    shippingMethods: {
      type: Array as PropType<AvailableShippingMethod[]>,
      default: () => [],
    },
  },
  setup(props, context) {
    const { cart } = useCart();
    const state = ref<SelectedShippingMethod | null>(null);

    const {
      save: saveShippingProvider,
      error: errorShippingProvider,
      loading: isLoading,
    } = useShippingProvider();

    const selectedShippingMethod = computed(() => state.value);
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const isShippingMethodStepCompleted = computed(
      () => state.value?.method_code && !isLoading.value,
    );

    const selectShippingMethod = async (method: AvailableShippingMethod) => {
      const shippingData = {
        carrier_code: method.carrier_code,
        method_code: method.method_code,
      };

      state.value = await saveShippingProvider({
        shippingMethod: shippingData,
      });
    };

    if (props.shippingMethods.length == 1){
      selectShippingMethod(props.shippingMethods[0])
      // console.log(props.shippingMethods);
      context.emit("set-is-shipping-provider-loading", isLoading.value);
    }

    watch(
      () => isLoading.value,
      async (newVariance) => {
        if (!newVariance){
          context.emit('submit');
          context.emit("set-is-shipping-provider-loading", newVariance);
        }
      }   
    );
  
    return {
      errorShippingProvider,
      getShippingMethodPrice,
      isLoading,
      isShippingMethodStepCompleted,
      selectedShippingMethod,
      selectShippingMethod,
      state,
      totals,
    };
  },
});
